
import PropTypes from "prop-types"
import React, { Component, useState } from 'react'
import Typed from 'typed.js'
import AnimHeader from "./shared/animheader"

import Lottie from 'react-lottie'
import animationData from './lottie/lf20_GFE6AO.json'
import ArrowScroll from './lottie/arrow-to-pointer-white.json'

import Link from "./shared/customlink.js"
import { navigate } from "gatsby"

import { TransitionPortal } from "gatsby-plugin-transition-link"
import TransitionLink from 'gatsby-plugin-transition-link'

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";

import ReactTimeout from 'react-timeout'

import { Parallax } from 'react-scroll-parallax';

import withSizes from 'react-sizes'

import "./herosplit.scss";

class HeroSplit extends Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      direction: 1,
      isPaused:true,
      textAnimLeft: false,
      textAnimRight: false,
      isMobile: false
    }

    this.leftHeader = React.createRef();
    this.rightHeader = React.createRef();
    this.leftPanel = React.createRef();
    this.rightPanel = React.createRef();
    this.arrowIndicator = React.createRef();
  }

  static propTypes = {
    paneLeftTitle: PropTypes.array,
    paneRightTitle: PropTypes.array,
  }

  static defaultProps = {
    paneLeftTitle: [],
    paneRightTitle: [],
  }

  componentDidMount() {
    
    this.setState({direction:1, isPaused:true})

    this.isTransitioning = false;

    this.panelFullLeft = CSSRulePlugin.getRule(".hero-split-container__paneleft:after");
    this.panelFullRight = CSSRulePlugin.getRule(".hero-split-container__paneright:after");

    TweenMax.set(this.panelFullRight, { opacity: 0, width: '50vw' } );
    TweenMax.set(this.panelFullLeft, { opacity: 1, width: '100vw' } );

    TweenMax.set(this.rightPanel.current, { x: '80vw', 'z-index': 2 } );
    TweenMax.set(this.leftPanel.current, { width: '100vw' } );

    this.isTransitioning = true;
    this.playIntro();

    TweenMax.to(this.arrowIndicator.current, .5, { y: 10, repeat:-1, yoyo:true });
  }

  playIntro() {
    let tl = new TimelineMax();
    // todo: shift delay to onload
    tl.delay(4.0);
    tl.to(this.panelFullLeft, 1.4, { opacity: 0, onStart: () => { this.setState({textAnimLeft: true}) } } );
    if (this.props.isMobile) {
      TweenMax.set(this.rightPanel.current, { x: 0 });
      this.isTransitioning = false;
    } else {
      tl.to(this.rightPanel.current, 1, { x: 0, ease: Power4.easeOut, onComplete: () => { this.isTransitioning = false; this.setState({textAnimRight: true}) } }, '+=-1' );
    }
    //tl.to([this.panelFullLeft, this.leftPanel.current], 1, { width: '50vw', ease: Power4.easeOut }, '-=1' );
    tl.play();
  }

  // arrowAnimationPlay = () => {
  //   this.setState({direction:1, isPaused:false})
  // }
  // arrowAnimationReset = () => {
  //   this.setState({direction:-1})
  // }

  scrollToElement = () => {
    var scrollElement = document.getElementById('work-life-hp')
    scrollElement.scrollIntoView({ behavior: 'smooth' })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isMobile === false && this.props.isMobile) {
      if (this.isTransitioning) {
        window.location.reload();
      }
    }
  }

  transitionRight = () => {
    //let panelFull = CSSRulePlugin.getRule(".hero-split-container__paneright:after");
    if (this.props.isMobile) {
       navigate("/work-life/", { state: { fromHome: true } });
    } else {
      this.isTransitioning = true;
      TweenMax.set(this.rightPanel.current, { 'z-index': 3} );
      //TweenMax.to(this.panelFullRight, 1, { opacity: 1, ease: Power4.easeOut } );
      TweenMax.to([this.panelFullRight], 1, { width: '100vw', onComplete: () => { navigate("/work-life/", { state: { fromHome: true } }) } } );      
    }

  }

  transitionLeft = () => {
    //let panelFull = CSSRulePlugin.getRule(".hero-split-container__paneleft:after");
    if (this.props.isMobile) {
      navigate("/work-place/", { state: { fromHome: true } });
    } else {
      TweenMax.to([this.panelFullRight, this.rightPanel.current], 1, { x: '50vw', ease: Power4.easeOutIn, onComplete: () => { navigate("/work-place/", { state: { fromHome: true } }) } } );      
    }
    this.isTransitioning = true;
    //TweenMax.set(this.leftPanel.current, { 'z-index': 3} );
    //TweenMax.to(this.panelFullLeft, 1, { opacity: 1, ease: Power4.easeOut } );
  }

  hoverLeft = () => {
    if (!this.isTransitioning) {
      //let panelFull = CSSRulePlugin.getRule(".hero-split-container__paneleft:after");
      TweenMax.to(this.panelFullLeft, .5, { opacity: 1 } );
    }
  }

  hoverRight = () => {
    if (!this.isTransitioning) {
      //let panelFull = CSSRulePlugin.getRule(".hero-split-container__paneright:after");
      TweenMax.to(this.panelFullRight, .5, { opacity: 1 } );
    }
  }

  hoverLeftOff = () => {
    if (!this.isTransitioning) {
      //let panelFull = CSSRulePlugin.getRule(".hero-split-container__paneleft:after");
      TweenMax.to(this.panelFullLeft, .5, { opacity: 0 } );
    }
  }

  hoverRightOff = () => {
    if (!this.isTransitioning) {
      //let panelFull = CSSRulePlugin.getRule(".hero-split-container__paneright:after");
      TweenMax.to(this.panelFullRight, .5, { opacity: 0 } );
    }
  }

  render() {

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const ArrowScrollOptions = {
      loop: false,
      autoplay: false, 
      animationData: ArrowScroll,
      direction: this.state.direction
    };

    let headerMode = (this.props.isMobile) ? 'init' : 'suspend';

    return (
    <div className={'hero-split-container'} >


      <div className={['hero-split-container__paneleft'].join(' ')} ref={this.leftPanel}>
        

          <div className={'hero-panel-inner-left'}>
            <div className={'hero-split-container__paneheader'} ref={this.leftHeader} >

              <div className={'link-item'} onMouseEnter={this.hoverLeft} onMouseLeave={this.hoverLeftOff} onClick={this.transitionLeft}>
                <AnimHeader typeText={this.props.paneLeftTitle} 
                          mode={headerMode}
                          startAnim={this.state.textAnimLeft}
                          inverted="true" 
                          arrowLink={true}
                          eyeBrowText="THE BUILDING"
                          animateCursor={true}
                          descText="Discover a next-gen building that enhances community through connectivity and productivity by design. Recruiting has never been this&nbsp;easy."
                          buttonText="Explore the Building"
                          buttonClass="cta-desktop-hidden btn-inverted"
                          buttonLink="/work-place"
                          isHome={true} />
              </div>
              <div className="arrow-scroll-desktop-hidden" ref={this.arrowIndicator} onClick={this.scrollToElement} >
                <Lottie options={ArrowScrollOptions} 
                height={44} 
                width={44} 
                direction={this.state.direction}
                isPaused={true}
                />
              </div>
            </div>
          </div>

      </div>

      <div id="work-life-hp" className={['hero-split-container__paneright'].join(' ')} ref={this.rightPanel}>
          
          <div className={'hero-split-container__paneheader'} ref={this.rightHeader} >

            <div className={'link-item'} onMouseEnter={this.hoverRight} onMouseLeave={this.hoverRightOff} onClick={this.transitionRight}>
              <AnimHeader typeText={this.props.paneRightTitle} 
                      mode={headerMode}
                      startAnim={this.state.textAnimRight}
                      inverted="true"
                      arrowLink={true}
                      eyeBrowText="THE NEIGHBORHOOD"
                      animateCursor={true}
                      descText="Discover the Bay Area’s best-kept secret: vibrant Uptown Oakland. Find out what a great commute and community look&nbsp;like."
                      buttonText="Explore the neighborhood"
                      buttonClass="cta-desktop-hidden btn-inverted"
                      buttonLink="/work-life"
                      isHome={true}  />
            </div>
          </div>

      </div>

      {/*<div className={'arrowindicator'}         
        onMouseEnter={this.arrowAnimationPlay}
        onMouseLeave={this.arrowAnimationReset}
        onClick={this.scrollToElement}  >
        <Lottie options={ArrowScrollOptions} 
        height={44} 
        width={44} 
        direction={this.state.direction}
        isPaused={this.state.isPaused}
        />
      </div>*/}
    </div>
    )
  }

}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 1022,
})

export default withSizes(mapSizesToProps)(HeroSplit);
