import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import Layout from "../components/layout/layout"
import SectionContainer from "../components/layout/sectioncontainer"
import HeroSplit from "../components/herosplit"

import SEO from "../components/seo"

import ImgPreload1 from '../images/hero/wpe-gradient.jpg';
import ImgPreload2 from '../images/hero/wle-gradient.jpg';
import ImgPreload3 from '../images/hero/wpe-hero-full.jpg';
import ImgPreload4 from '../images/hero/wle-hero-full.jpg';

export default class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.layout = React.createRef();
  }

  componentDidMount() {

    this.toggleGallery = this.layout.current.toggleGallery;
    this.navSwitchCallback = this.layout.current.navSwitchCallback;

    
    setTimeout(
      function() {
        this.minimumLoaderTimeReached = true;

        // force loader to hide if nothing has loaded.
        if (this.imagesLoaded  == 4){
          this.layout.current.hidePreloader();
        }
      }
      .bind(this),
      3000
    );

    this.preLoadImages();

    this.imagesLoaded = 0;
    this.minimumLoaderTimeReached = false;
  }

  handleImageLoaded = () => {
    console.log('handling loaded images');
    this.imagesLoaded = Number(this.imagesLoaded) + 1;

    if (this.imagesLoaded == 4 && this.minimumLoaderTimeReached) {
        this.layout.current.hidePreloader();
    }
  }

  preLoadImages = () => {
    const img1 = new Image();
    img1.src = ImgPreload1
    console.log(ImgPreload1)
    img1.addEventListener('load', this.handleImageLoaded);

    const img2 = new Image();
    img2.src = ImgPreload2;
    img2.addEventListener('load', this.handleImageLoaded);

    const img3 = new Image();
    img3.src = ImgPreload3;
    img3.addEventListener('load', this.handleImageLoaded);

    const img4 = new Image();
    img4.src = ImgPreload4;
    img4.addEventListener('load', this.handleImageLoaded);
  }

  render() {
    return (
      <Layout ref={this.layout} nullFooter={true}>
        <SEO title="Home" description="875,000 SF of next-gen workspace in a connected vertical community situated in the heart of Uptown Oakland." />
        
        <HeroSplit paneLeftTitle={[`work/place`,`experience`]} paneRightTitle={[`work/life`,`experience`]} ></HeroSplit>
        <div className="preloader-helper hidden">
        {/*<img
          src={ImgPreload1}
          onLoad={() => { this.handleImageLoaded(); }}
          alt="preloader-img"
        />
        <img
          src={ImgPreload2}
          onLoad={() => { this.handleImageLoaded(); }}
          alt="preloader-img"
        />
        <img
          src={ImgPreload3}
          onLoad={() => { this.handleImageLoaded(); }}
          alt="preloader-img"
        />
        <img
          src={ImgPreload4}
          onLoad={() => { this.handleImageLoaded(); }}
          alt="preloader-img"
        />*/}
        </div>

      </Layout>
    )
  }
}
